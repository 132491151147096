@import "../../../assets/scss/variables.scss";
@import "../../../assets/scss/mixins.scss";

.DisputeManagement {
  .title-page {
    margin: 0;
    color: black;
    font-family: $roboto_light;
    font-size: 30px;
  }

  .subtitle-page {
    margin: 0;
    color: black;
    font-family: $roboto_bold;
    font-size: 20px;
  }

  tr {
    font-family: $roboto_light;
    font-size: 13px;
  }

  .expandid {
    span {
      align-items: center;
      display: flex;
      gap: 10px;
      font-family: $roboto_bold;
      width: 100%;

      p {
        margin: 5px 0;
        font-family: $roboto_light;
      }
    }
  }

  .badge {
    display: inline-block;
    padding: 3px 7px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    color: white;
    width: min-content !important;
    font-family: $roboto_bold;
    border-radius: 0.25rem;

    &.badge-success {
      background-color: #21ae41;
    }

    &.yellow {
      background-color: rgb(211, 211, 64);
    }

    &.orange {
      background-color: rgb(241, 167, 20);
    }

    &.blue {
      background-color: rgb(20, 145, 241);
    }

    &.black {
      background-color: black;
    }

    &.badge-error {
      background-color: #da0419;
    }
  }
}
