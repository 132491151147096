.StateSelect {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  box-sizing: border-box;

  label {
    margin: 0 0 5px 0;
  }

  select {
    background-color: #f0f4f7;
    border-color: #f0f4f7;
    border-radius: 5px;
    border-style: solid;
    border-width: 2px;
    color: #5e6977;
    font-size: 14px;
    font-weight: 600;
    height: 48px;
    padding: 5px 15px;
    box-sizing: border-box;
    width: max-content;
    outline: none;
  }

  .invalid-feedback {
    display: block;
    font-size: 12px;
    color: #e74c3c;
  }
}
