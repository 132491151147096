@import "../../../../../assets/scss/variables.scss";
@import "../../../../../assets/scss/mixins.scss";

.Responsible {
  margin-bottom: 100px;

  @include breakpoint(tablet) {
    margin-bottom: 0;
  }

  div {
    #birthdate {
      background: #ffffff;
      border-color: #d9d9d9;
      border-radius: 6px;
      border-style: solid;
      border-width: 1px;
      padding: 5px 11px;

      &:disabled {
        color: #71748d;
        background-color: rgba(0, 0, 0, 0.04);
        border-color: #d9d9d9;
        box-shadow: none;
        cursor: not-allowed;
        opacity: 1;
      }
    }

    input,
    .ant-select,
    .ant-picker,
    .ant-input-number {
      width: 100%;
      font-size: 15px;
      font-family: $roboto_bold;
      color: #71748d;
    }
  }

  .subtitle-page {
    margin: 20px 0;
    color: black;
    font-family: $roboto_regular;
    font-size: 20px;
  }

  .container-inputs {
    display: flex;
    gap: 20px;
    flex-direction: column;

    .left-inputs,
    .right-inputs {
      width: 100%;
    }

    @include breakpoint(desktop-small) {
      flex-direction: row;

      .left-inputs,
      .right-inputs {
        width: 50%;
      }
    }
  }
}
