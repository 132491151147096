@import "../../../../assets/scss/variables.scss";
@import "../../../../assets/scss/mixins.scss";

.Profile {
  .content {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    background-color: #fff;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    padding: 24px;

    .title {
      color: #595959;
      font-size: 16px;
      font-family: $roboto_bold;
    }

    .form {
      p {
        font-size: 14px;
        font-family: $roboto_regular;
        color: black;
        margin: 0 0 16px 0;
      }
    }

    .flex-column {
      display: flex;
      flex-direction: column;
      gap: 8px;

      button {
        border-radius: 3px;
      }
    }
  }
}
