@import "../../../../assets/scss/variables.scss";
@import "../../../../assets/scss/mixins.scss";

.DocsRegister {
  height: 100% !important;

  .content {
    align-items: left;
    background-color: #fff;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: left;
    min-height: 80vh;
    padding: 24px 24px 50px;

    .title {
      align-items: center;
      color: #595959;
      cursor: pointer;
      display: flex;
      font-family: $roboto_bold;
      font-size: 16px;

      .ion--arrow-back {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='none' stroke='black' stroke-linecap='round' stroke-linejoin='round' stroke-width='48' d='M244 400L100 256l144-144M120 256h292'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: inline-block;
        height: 20px;
        margin-right: 5px;
        width: 20px;
      }
    }

    .info-blue {
      width: 100%;
      background-color: #E6F7FF;
      border: 1px solid #91D5FF;
      padding: 10px 16px;
      box-sizing: border-box;
      margin-bottom: 24px;

      p {
        margin: 0;
        font-size: 15px;
      }
    }

    .form {
      // position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      min-height: 50vh;

      p {
        font-size: 14px;
        font-family: $roboto_regular;
        color: black;
        margin: 0 0 16px 0;

        span {
          color: red;
        }
      }

      input {
        border-radius: 2px;
      }

      .container-inputs {
        display: flex;
        flex-direction: column;
        gap: 40px;
      }

      .infos-docs {
        .mini-info {
          margin: 5px 0 0 0;
          font-size: 13px;
          font-family: $roboto_light;
        }

        ul {
          padding: 10px 22px;

          li {
            font-family: $roboto_light;
            font-size: 15px;
          }
        }

        .info-green {
          width: 100%;
          background-color: #F6FFED;
          border: 1px solid #B7EB8F;
          padding: 10px 16px;
          box-sizing: border-box;
          margin-bottom: 40px;
    
          p {
            margin: 0;
            font-size: 15px;
          }
        }
        
      }

      .container-buttons {
        display: flex;
        flex-direction: column;
        gap: 16px;

        button {
          border-radius: 3px;
        }
      }
    }
  }
}

.ant-modal-body {
  ul {
    padding: 10px 22px;

    li {
      font-family: $roboto_light;
      font-size: 15px;
    }
  }

  .text-outline {
    text-decoration: underline;
    font-size: 15px;
  }

  .info-yellow {
    width: 100%;
    background-color: #FFFBE6;
    border: 1px solid #FFE58F;
    padding: 10px 16px;
    box-sizing: border-box;
    margin-bottom: 24px;

    p {
      margin: 0;
      font-size: 15px;
    }
  }
}