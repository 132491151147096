@import '../../../../assets/scss/variables.scss';
@import '../../../../assets/scss/mixins.scss';
.EventCreate {
  padding-bottom: 100px;

  @include breakpoint(tablet) {
    padding-bottom: 0;
  }

  .goBack {
    cursor: pointer !important;

    &:hover {
      color: #1677ff;
    }
  }

  label {
    &::before {
      display: inline-block;
      margin-inline-end: 4px;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
    }
  }

  .container-question-button {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    @include breakpoint(tablet) {
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
    }

    h3 {
      font-family: $roboto_bold;
      font-size: 20px;

      @include breakpoint(tablet) {
        font-size: 30px;
      }
    }
  }

  .title-page {
    margin: 0;
    color: black;
    font-family: $roboto_light;
    font-size: 30px;
  }

  .container-subtitle {
    display: flex;
    justify-content: space-between;

    .badge {
      display: inline-block;
      padding: 5px 7px;
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      color: white;
      font-family: $roboto_bold;
      border-radius: 0.25rem;
      height: fit-content;

      &.badge-success {
        background-color: #21ae41;
      }

      &.badge-error {
        background-color: #da0419;
      }

      &.badge-black {
        background-color: black;
      }
    }
  }

  .subtitle-page {
    margin: 0;
    color: black;
    font-family: $roboto_bold;
    font-size: 20px;
  }

  .container-inputs {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    display: flex;
    flex-direction: column;

    .ant-form-item {
      .ant-form-item-row {
        flex-direction: column !important;
        height: max-content;
        display: flex;

        .ant-col {
          width: 100% !important;
          display: flex;
          flex: 0 0 0 !important;
          flex-grow: unset !important;
        }
      }
    }

    @include breakpoint(tablet) {
      flex-direction: row;
    }

    .container-left,
    .container-right {
      width: 100%;

      @include breakpoint(tablet) {
        width: 50%;
      }
    }

    .container-right {
      // gap: 30px;
      display: flex;
      flex-direction: column;
    }

    div {
      input,
      .ant-select,
      .ant-picker,
      .ant-input-number {
        width: 100%;
        font-size: 15px;
        font-family: $roboto_bold;
        color: #71748d;
      }
    }
  }

  .container-table {
    .badge {
      display: inline-block;
      padding: 5px 7px;
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
      text-transform: capitalize;
      font-family: $roboto_bold;
      background-color: #f3b600;
      color: #2e2f39;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      border-radius: 0.25rem;
    }
  }

  .container-buttons {
    width: 100%;
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;
    gap: 10px;

    button {
      font-family: $roboto_medium;
    }
  }

  .link {
    margin-top: 20px;
    display: flex;

    button {
      display: -ms-flexbox;
      display: flex;
      padding: 10px;
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.5;
      color: #75748d;
      text-align: center;
      white-space: nowrap;
      background-color: #efeff6;
      cursor: pointer;
      border: 1px solid #d2d2e4;
      border-right: 0;
      border-radius: 4px;
      border-radius: 6px 0 0 6px;
    }

    input {
      background-color: #e9ecef;
      border-radius: 0 6px 6px 0;
    }
  }
}

.required {
  label {
    &::before {
      display: inline-block;
      margin-inline-end: 4px;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
    }
  }
}

.jodit-add-new-line {
  display: none !important;
}
