@import "../../../../assets/scss/variables.scss";
@import "../../../../assets/scss/mixins.scss";

.container-table {
  // box-shadow: inset -10px 0 8px -8px rgba(5, 5, 5, 0.09);
  position: relative;

  .shadow {
    overflow-x: scroll;
  }
  
  .table {
    overflow: hidden;
    z-index: 1;
    border-collapse: collapse;
    box-sizing: border-box;
  }

  table,
  th,
  td {
    padding: 0;
    margin: 0;
  }

  td {
    white-space: nowrap;
    // overflow: hidden;
    text-overflow: ellipsis;
    padding: 16px 16px;
    font-family: $roboto_regular;

  }

  .table thead th {
    vertical-align: middle;
    background-color: #fafafa;
    // position: relative;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.88);
    font-weight: 600;
    border-bottom: 1px solid #f0f0f0;
  }

  .table thead {
    z-index: 1;
    position: static;
  }

  .table td,
  .table th {
    padding: 20px;
    vertical-align: middle;
    border: 1px solid #e6e6f2;
    font-weight: normal;
  }

  .table-hover tbody tr:hover {
    transition: background 0.2s ease;
    background-color: rgba(230, 230, 242, 0.5);
  }

  tr.group,
  tr.group:hover {
    background-color: #5969ff !important;
    color: #fff;
    font-family: "Circular Std Medium";
    font-size: 18px;
  }

  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 40;
    box-shadow: inset -10px 0 8px -8px rgba(5, 5, 5, 0.09);
    width: 30px;
    transition: box-shadow 0.3s;
    content: "";
    pointer-events: none;
    z-index: 20;
  }
}
