@import "../../../../../assets/scss/variables.scss";
@import "../../../../../assets/scss/mixins.scss";

.BusinessActivity {
  margin-bottom: 100px;

  @include breakpoint(tablet) {
    margin-bottom: 0;
  }

  .subtitle-page {
    margin: 20px 0;
    color: black;
    font-family: $roboto_regular;
    font-size: 20px;
  }

  .container-inputs {
    display: flex;
    gap: 20px;
    flex-direction: column;

    .left-inputs,
    .right-inputs {
      width: 100%;
    }

    @include breakpoint(desktop-small) {
      flex-direction: row;

      .left-inputs,
      .right-inputs {
        width: 50%;
      }
    }
  }
}
