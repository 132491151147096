@import "../../../../assets/scss/variables.scss";
@import "../../../../assets/scss/mixins.scss";

.AccessCreate {
  .title-page {
    margin: 0;
    color: black;
    font-family: $roboto_light;
    font-size: 30px;
  }

  .container-subtitle {
    display: flex;
    justify-content: space-between;

    .badge {
      display: inline-block;
      padding: 5px 7px;
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      color: white;
      font-family: $roboto_bold;
      border-radius: 0.25rem;
      height: fit-content;

      &.badge-success {
        background-color: #21ae41;
      }

      &.badge-error {
        background-color: #da0419;
      }

      &.badge-black {
        background-color: black;
      }
    }
  }

  .subtitle-page {
    margin: 0;
    color: black;
    font-family: $roboto_bold;
    font-size: 20px;
  }

  .container-inputs {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    display: flex;
    flex-direction: column;

    .ant-form-item {
      .ant-form-item-row {
        flex-direction: column !important;
        height: max-content;
        display: flex;

        .ant-col {
          width: 100% !important;
          display: flex;
          flex: 0 0 0 !important;
          flex-grow: unset !important;
        }
      }
    }

    @include breakpoint(tablet) {
      flex-direction: row;
    }

    .container-left,
    .container-right {
      width: 100%;

      @include breakpoint(tablet) {
        width: 50%;
      }
    }

    .container-right {
      // gap: 30px;
      display: flex;
      flex-direction: column;
    }

    div {
      .info-date {
        background-color: rgba(0, 0, 0, 0.04);
        border-color: #d9d9d9;
        border-radius: 6px;
        border-style: solid;
        border-width: 1px;
        box-shadow: none;
        color: #71748d;
        cursor: not-allowed;
        margin: 0;
        opacity: 1;
        padding: 5px 11px;
      }

      input,
      .ant-select,
      .ant-picker,
      .ant-input-number,
      .info-date {
        width: 100%;
        font-size: 15px;
        font-family: $roboto_bold;
        color: #71748d;
      }
    }
  }

  .container-table {
    .badge {
      display: inline-block;
      padding: 5px 7px;
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
      text-transform: capitalize;
      font-family: $roboto_bold;
      background-color: #f3b600;
      color: #2e2f39;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      border-radius: 0.25rem;
    }
  }

  .container-buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 10px;

    button {
      font-family: $roboto_medium;
    }
  }

}
