@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.error-message {
  color: red;
  font-family: $roboto_regular;
  margin: 0;
}

.SelectTicket {
  display: flex;
  flex-direction: column;
  position: relative;

  & > p {
    color: black;
    font-size: 16px;
    font-family: $roboto_regular;
    margin: 0 0 5px 0;

    &.visible {
      opacity: 1;
    }
  }

  & > div {
    display: flex;
    flex-direction: column;

    & > label {
      color: black;
      font-size: 16px;
      font-family: $roboto_regular;
      margin-bottom: 10px;
      text-align: left;

      &::before {
        display: inline-block;
        margin-inline-end: 4px;
        color: #ff4d4f;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: '*';
      }
    }

    & > select {
      background-color: rgb(255, 255, 255);
      border: 1px solid rgb(221, 224, 228);
      border-radius: 4px;
      color: black;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      font-family: $roboto_regular;
      height: 48px;
      outline: 2px solid white;
      padding: 0 25px 0 5px;
      -webkit-appearance: none;

      & > option {
        color: black;
        cursor: pointer;

        &:hover {
          color: $white;
        }
      }
    }

    svg {
      bottom: 15px;
      fill: rgb(0, 151, 255);
      height: 18px;
      position: absolute;
      right: 10px;
      width: 18px;
    }
  }
  &.invalid {
    border-color: red;

    select {
      border-color: red;
    }

    div {
      svg {
        fill: red;
      }
    }
  }
}
