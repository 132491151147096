@import "./fonts";

$black: #000;
$lightgreen: #eff0d1;
$red: #d33f49;
$positive_variation_color: #00ff6c;
$negative_variation_color: #ee4c3d;
$green: #77ba99;
$green_variation: #6ab63f;
$green_trend: #1c5c3b;
$green_tutorial: #269012;
$red_trend: #eb9598;
$green_progress_bar: #19e27f;
$red_variation: #ff0000;
$white_bg: #f7f7f7;
$lightgray: #e2e2e2;
$gray_background: #696969;
$gray_trending_topics: #eaeaea;
$status_yellow: #ffb50d;
$status_green: #00c444;
$status_red: #ff0000;
$complete-green: #098d2c;
$complete-green-background: #e1efe1;
$darkgray: #bdbdbd;
$darkgray_text: #757575;
$lightgreen: #eff0d1;
$white: #ffffff;
$orange: #ffc400;

$roboto_light: Roboto-Light, sans-serif;
$roboto_bold: Roboto-Bold, sans-serif;
$roboto_regular: Roboto-Regular, sans-serif;
$roboto_thin: Roboto-Thin, sans-serif;
$roboto_medium: Roboto-Medium, sans-serif;
$roboto_italic: Roboto-Italic, sans-serif;

$josefinsans_light: JosefinSans-Light, sans-serif;
$josefinsans_bold: JosefinSans-Bold, sans-serif;
$josefinsans_regular: JosefinSans-Regular, sans-serif;

$breakpoints: (
  "mobile-medium": 400px,
  "mobile-larger": 576px,
  "tablet": 768px,
  "tablet-large": 992px,
  "desktop-small": 1024px,
  "desktop-medium": 1440px,
  "desktop-large": 1600px,
  "desktop-extra-large": 1800px,
  "4k": 2300px,
);
