@import "../../../assets/scss/variables.scss";
@import "../../../assets/scss/mixins.scss";

.SaleInfo {
  padding-bottom: 100px;

  @include breakpoint(tablet) {
    padding-bottom: 0;
  }

  .title-page {
    margin: 0;
    color: black;
    font-family: $roboto_light;
    font-size: 30px;
  }

  .container-subtitle {
    display: flex;
    justify-content: space-between;

    .badge {
      display: inline-block;
      padding: 5px 7px;
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      color: white;
      font-family: $roboto_bold;
      border-radius: 0.25rem;
      height: fit-content;

      &.badge-success {
        background-color: #21ae41;
      }

      &.yellow {
        background-color: rgb(211, 211, 64);
      }

      &.orange {
        background-color: rgb(241, 167, 20);
      }

      &.blue {
        background-color: rgb(20, 145, 241);
      }

      &.black {
        background-color: black;
      }

      &.badge-error {
        background-color: #da0419;
      }
    }
  }

  .subtitle-page {
    margin: 0;
    color: black;
    font-family: $roboto_bold;
    font-size: 20px;
  }

  .container-inputs {
    display: flex;
    justify-content: space-between;
    gap: 30px;

    .container-left,
    .container-right {
      width: 50%;
    }

    div {
      input {
        width: 100%;
        font-size: 15px;
        font-family: $roboto_bold;
        color: #71748d;
      }
    }
  }

  .container-table {
    .badge {
      display: inline-block;
      padding: 5px 7px;
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
      text-transform: capitalize;
      font-family: $roboto_bold;
      background-color: #f3b600;
      color: #2e2f39;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      border-radius: 0.25rem;
    }
  }

  .container-buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    button {
      font-family: $roboto_medium;
    }
  }
}
