@import "../../../assets/scss/variables.scss";
@import "../../../assets/scss/mixins.scss";

.ExternalPayment {
  height: 100vh;
  overflow-y: scroll;

  header {
    background-color: #f6f9fc;
    display: flex;
    justify-content: center;
    min-height: 500px;
    padding: 16px;

    .container-header {
      max-width: 960px;
      width: 100%;

      .navigation {
        align-items: center;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        padding: 50px 0 80px 0;

        p {
          color: #246eea;
          font-family: $josefinsans_bold;
          font-size: 20px;
          font-weight: bold;
          letter-spacing: 1px;
          margin: 0;
        }

        img {
          width: 170px;
        }
      }

      h2 {
        font-family: $josefinsans_bold;
        font-size: 28px;
        font-weight: bold;
        margin: 0;
        text-align: center;
      }
    }
  }

  .content {
    padding: 16px;
    min-height: 500px;

    .details-content {
      max-width: 800px;
      height: 300px;
      box-sizing: border-box;
      padding: 30px;
      margin: -200px auto 0;
      background: #fff;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      position: relative;
      box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1),
        0 5px 15px rgba(0, 0, 0, 0.07);

      .info-details {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 10px;

        span {
          font-size: 28px;
          font-weight: 600;
          font-family: $josefinsans_bold;
        }

        p {
          margin: 0;
          font-family: $josefinsans_bold;
          &.price {
            font-size: 22px;
            font-weight: bold;
            color: #31bd27;
          }
        }

        strong {
          font-size: 19px;
          font-family: $josefinsans_bold;
        }
      }

      .text-absolut {
        bottom: 30px;
        color: #5e6977;
        font-family: $josefinsans_regular;
        font-size: 14px;
        margin-top: 20px;
        position: absolute;
      }
    }

    .forms-container {
      margin-top: 100px;
      width: 100%;

      .container-checkout {
        max-width: 960px;
        width: 100%;
        margin: 0 auto;
        box-sizing: border-box;
      }
    }
  }
}

.ErrorPage {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f3f4f6;
  padding: 3rem;
  box-sizing: border-box;

  .content {
    max-width: 28rem;
    text-align: center;
  }

  .icon {
    margin: 0 auto;
    height: 3rem;
    width: 3rem;
    color: #31bd27;
  }

  .title {
    margin-top: 1rem;
    font-size: 1.875rem;
    font-weight: bold;
    color: #333;
  }

  .message {
    margin-top: 1rem;
    color: #6b7280;
  }

  .button-container {
    margin-top: 1.5rem;
  }

  .button {
    display: inline-flex;
    align-items: center;
    border-radius: 0.375rem;
    background-color: #3490dc;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: #fff;
    text-decoration: none;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: background-color 0.2s;

    &:hover {
      background-color: #2779bd;
    }

    &:focus {
      outline: none;
      ring: 2px;
      ring-color: #3490dc;
      ring-offset: 2px;
    }
  }
}
