@import "../../../assets/scss/variables.scss";
@import "../../../assets/scss/mixins.scss";

.Accreditation {
  .title-page {
    margin: 0;
    color: black;
    font-family: $roboto_light;
    font-size: 30px;
  }

  .subtitle-page {
    margin: 0;
    color: black;
    font-family: $roboto_bold;
    font-size: 20px;
  }

  .container-inputs {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .box-input {
      p {
        font-family: $roboto_bold;
        font-size: 15px;
        margin: 0 0 10px 0;
        color: #71748d;
      }

      div {
        display: flex;

        button {
          display: -ms-flexbox;
          display: flex;
          padding: 10px;
          margin-bottom: 0;
          font-size: 14px;
          font-weight: 400;
          line-height: 1.5;
          color: #75748d;
          text-align: center;
          white-space: nowrap;
          background-color: #efeff6;
          cursor: pointer;
          border: 1px solid #d2d2e4;
          border-right: 0;
          border-radius: 4px;
          border-radius: 6px 0 0 6px;
        }

        input {
          background-color: #e9ecef;
          border-radius: 0 6px 6px 0;
        }
      }
    }
  }
}