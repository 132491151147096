@import "../../../../assets/scss/variables.scss";
@import "../../../../assets/scss/mixins.scss";

.PriceList {
  .title-page {
    margin: 0;
    color: black;
    font-family: $roboto_light;
    font-size: 30px;
  }


  .subtitle-page {
    margin: 0;
    color: black;
    font-family: $roboto_bold;
    font-size: 20px;
  }

  .container-table {
    
    .no-break-text {
      white-space: nowrap;
    }
  }
}