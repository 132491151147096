@import "../../../../assets/scss/variables.scss";
@import "../../../../assets/scss/mixins.scss";

.FinalRegister {
  height: 100% !important;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: #D9F7BE;
  z-index: 10;

  .header-final {
    padding: 66px 24px 22px 24px;
    background-color: #D9F7BE;
  }

  .content {
    align-items: center;
    background-color: #fff;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: left;
    min-height: 80vh;
    padding: 24px 24px 50px;

    .title {
      font-family: $roboto_bold;
      font-size: 20px;
      color: $black;
      margin: 10px 0 20px 0;
    }

    .describe {
      margin: 40px 0;
      text-align: center;
      font-size: 16px;
      font-family: $roboto_light;
    }

    .container-buttons {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;

      button {
        border-radius: 3px;
      }
    }
  }
}