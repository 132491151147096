@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';

.details-container {
  margin-bottom: 24px;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: rgba(25, 31, 40, 0.1) 0px 2px 10px 0px;
}

.summary {
  font-size: 15px;
  font-family: $roboto_bold;
  cursor: pointer;
  background: white;
  border: none;
  border-radius: 8px;
  text-align: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease;

  @include breakpoint(tablet-large) {
    font-size: 19px;
  }
}

.icon {
  display: flex;
  align-items: center;
  transition: transform 0.3s ease;
}

.content-details {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-out;
  padding: 0 10px;
  background-color: white;

  &.open {
    max-height: 100vh;
    transition: max-height 0.5s ease-in;
  }

  div {
    display: flex;
    flex-direction: column;
    gap: 16px;

    padding-top: 24px;
    color: rgb(132, 140, 155);
  }

  p {
    margin: 0;
    font-size: 13px;
    font-family: $roboto_regular;

    @include breakpoint(tablet-large) {
      font-size: 16px;
    }
  }
}

.summary .icon svg {
  width: 24px;
  height: 24px;
  fill: #000;
}

.content-details.open ~ .summary .icon {
  transform: rotate(180deg);
}
