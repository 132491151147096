.SellerDropdown {
  overflow: hidden;
  max-width: 250px;
  text-overflow: ellipsis;

  .ant-select-selector {
    min-width: 250px;
    width: auto;
    max-width: 100%;
    display: inline-flex;
    overflow: hidden;
  }

  .ant-select-item-option {
    min-width: 200px;
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
