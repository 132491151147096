@import "../../../assets/scss/variables.scss";
@import "../../../assets/scss/mixins.scss";

.SimulationTax {
  .title-page {
    margin: 0;
    color: black;
    font-family: $roboto_light;
    font-size: 30px;
  }

  .subtitle-page {
    margin: 0;
    color: black;
    font-family: $roboto_bold;
    font-size: 20px;
  }

  .container-serch {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .box-input {
      display: flex;
      flex-direction: column;

      p {
        font-family: $roboto_bold;
        font-size: 15px;
        color: #71748d;
      }

      input {
        border-radius: unset;
        max-width: 200px;
      }
    }

    button {
      border-radius: 0;
    }
  }
}
