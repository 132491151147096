@import "../../../assets/scss/variables.scss";
@import "../../../assets/scss/mixins.scss";

.CheckoutForm {
  padding-bottom: 150px;

  h2 {
    font-family: $josefinsans_bold;
    margin: 40px 0 20px 0;
    font-size: 18px;
    display: flex;
    text-align: left;
    margin: 0;
  }

  .row {
    display: flex;
    flex-direction: column;

    @include breakpoint(tablet) {
      flex-direction: row;
    }
  }

  .installments-container {
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    label {
      margin: 0 0 5px 0;
    }

    select {
      background-color: #f0f4f7;
      border-color: #f0f4f7;
      border-radius: 5px;
      border-style: solid;
      border-width: 2px;
      color: #5e6977;
      font-size: 14px;
      font-weight: 600;
      height: 48px;
      padding: 5px 10px;
      width: max-content;
    }
  }

  .payment-type-container {
    h2 {
      margin: 40px 0 20px 0;
      font-family: $josefinsans_bold;
      font-size: 18px;
      display: flex;
      text-align: left;
    }

    .payment_buttons {
      display: flex;
      gap: 12px;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      @include breakpoint(tablet) {
        flex-direction: row;
      }

      .payment_type_button {
        display: flex;
        border: 2px solid #a1a1a1;
        border-radius: 8px;
        background-color: white;
        padding: 20px 40px;
        width: 100%;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: #a1a1a1;
        transition: all 0.15s ease;
        font-size: 18px;
        font-family: $josefinsans_regular;

        &.active {
          color: #0a0a0a;
          background: #f6fcf7;
          border-color: #7dc855;
        }
      }
    }
  }

  .payment_form {
    padding-top: 12px;

    .payment__redirect {
      border-left: 3px solid #dadada;
      background-color: #f0f4f7;
      padding: 10px;
    }
  }

  .payment-container {
    display: flex;
    justify-content: center;
    padding: 60px 0 40px 0;

    .loading-div {
      .loader-text {
        display: block;
        text-align: center;
        margin-top: 20px;
        font-size: 20px;
        font-weight: 600;
        padding-bottom: 5px;
      }

      .loader {
        display: block;
        position: relative;
        height: 15px;
        width: 140px;
        background-image: linear-gradient(#2c3e50 15px, transparent 0),
          linear-gradient(#2c3e50 15px, transparent 0),
          linear-gradient(#2c3e50 15px, transparent 0),
          linear-gradient(#2c3e50 15px, transparent 0);
        background-repeat: no-repeat;
        background-size: 15px auto;
        background-position: 0 0, 40px 0, 80px 0, 115px 0;
        animation: pgfill 1s linear infinite;
      }

      @keyframes pgfill {
        0% {
          background-image: linear-gradient(#2c3e50 15px, transparent 0),
            linear-gradient(#2c3e50 15px, transparent 0),
            linear-gradient(#2c3e50 15px, transparent 0),
            linear-gradient(#2c3e50 15px, transparent 0);
        }
        25% {
          background-image: linear-gradient(#7dc855 15px, transparent 0),
            linear-gradient(#2c3e50 15px, transparent 0),
            linear-gradient(#2c3e50 15px, transparent 0),
            linear-gradient(#2c3e50 15px, transparent 0);
        }
        50% {
          background-image: linear-gradient(#2c3e50 15px, transparent 0),
            linear-gradient(#7dc855 15px, transparent 0),
            linear-gradient(#2c3e50 15px, transparent 0),
            linear-gradient(#2c3e50 15px, transparent 0);
        }
        75% {
          background-image: linear-gradient(#2c3e50 15px, transparent 0),
            linear-gradient(#2c3e50 15px, transparent 0),
            linear-gradient(#7dc855 15px, transparent 0),
            linear-gradient(#2c3e50 15px, transparent 0);
        }
        100% {
          background-image: linear-gradient(#2c3e50 15px, transparent 0),
            linear-gradient(#2c3e50 15px, transparent 0),
            linear-gradient(#2c3e50 15px, transparent 0),
            linear-gradient(#7dc855 15px, transparent 0);
        }
      }
    }

    button {
      background: #246eea;
      border-radius: 8px;
      border: 0;
      color: #fff;
      cursor: pointer;
      font-family: $josefinsans_regular;
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 1;
      padding: 20px 60px;
      text-decoration: none;
      text-transform: uppercase;
      transition: all 0.15s ease;
      white-space: nowrap;
    }
  }
}

.pix-feedback {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 0 40px 0;

  h2 {
    font-family: $josefinsans_bold;
    margin: 40px 0 20px 0;
    font-size: 18px;
    display: flex;
    text-align: left;
    margin: 0;
  }

  img {
    max-width: 100%;
  }

  .pix-copy-paste {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 300px;
    height: 30px;
    border: 1px solid #b9b9b9;
    overflow: hidden;

    .pix-code {
      background-color: #e7e7e7;
      white-space: nowrap;
      width: 350px;
      overflow: scroll;
      padding: 8px;
      scrollbar-width: none;
    }

    button {
      color: white;
      background-color: #639b43;
      padding: 8px;
      cursor: pointer;
      border: unset;
    }
  }
}

.boleto-feedback {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0 0 40px 0;
  width: 100%;

  iframe {
    display: none;
    width: 100%;

    @include breakpoint(tablet) {
      display: flex;
    }
  }

  button {
    background: #246eea;
    border-radius: 8px;
    border: 0;
    color: #fff;
    cursor: pointer;
    font-family: $josefinsans_regular;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 1;
    padding: 20px 60px;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 0.15s ease;
    white-space: nowrap;
  }
}

.PaymentSuccess {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  .content {
    max-width: 28rem;
    text-align: center;
  }

  .icon {
    margin: 0 auto;
    height: 3rem;
    width: 3rem;
    color: #28a745;
  }

  .title {
    margin-top: 1rem;
    font-size: 1.875rem;
    font-weight: bold;
    color: #343a40;
  }

  .message {
    margin-top: 1rem;
    color: #6c757d;
  }

  .order-info {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .total {
    font-size: 1.5rem;
    font-weight: bold;
    color: black;
  }
}

// error purchase

.OrderUnsuccessful {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px;
  box-sizing: border-box;

  .content {
    max-width: 400px;
    text-align: center;
  }

  .icon {
    margin: 0 auto;
    height: 48px;
    width: 48px;
    color: red;
  }

  .title {
    margin-top: 16px;
    font-size: 24px;
    font-weight: bold;
    color: #333;
  }

  .message {
    margin-top: 16px;
    color: #777;
  }

  .actions {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    align-items: center;

    @media (min-width: 640px) {
      flex-direction: row;
    }
  }

  button {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    border: unset;
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: background-color 0.2s;

    &.primary {
      background-color: #e65c50;
      border: 1px solid #e65c50;
      color: #fff;
    }

    &.primary:hover {
      border: 1px solid #246eea;
      background-color: #246eea;
    }

    &.secondary {
      background-color: #fff;
      border: 1px solid #ddd;
      color: #777;
    }

    &.secondary:hover {
      background-color: #f7f7f7;
      color: #333;
    }
  }
}
