@import '../../../../assets/scss/variables.scss';
@import '../../../../assets/scss/mixins.scss';

.AccountDetails {
  width: 100%;

  input,
  .ant-select,
  .ant-picker,
  .ant-input-number,
  .ant-select-selection-item {
    width: 100%;
    font-size: 15px;
    font-family: $roboto_bold;
    color: #71748d;

    &:disabled {
      color: #71748d;
      background-color: rgba(0, 0, 0, 0.04);
      border-color: #d9d9d9;
      box-shadow: none;
      cursor: unset;
      opacity: 1;
    }
  }

  .title-page {
    margin: 0;
    color: black;
    font-family: $roboto_light;
    font-size: 30px;
  }

  .subtitle-page {
    margin: 0;
    color: black;
    font-family: $roboto_bold;
    font-size: 20px;
  }

  .goBack {
    cursor: pointer !important;

    &:hover {
      color: #1677ff;
    }
  }

  .content-container {
    display: flex;
    gap: 20px;
    padding: 20px;
    box-sizing: border-box;
    flex-direction: column;
    width: 100%;

    .sider-container {
      width: 100% !important;
      max-width: 100% !important;
      min-width: 220px !important;
      overflow-y: unset;

      .ant-menu-item {
        height: 50px;
      }

      .ant-layout-sider-children {
        width: 100% !important;
        gap: 10px;

        .ant-badge-count {
          transform: translate(125%, -50%);
          min-width: 11px;
          max-height: 10px;
          font-size: 5px;
          color: red !important;
        }
      }
    }

    .content-box {
      background-color: red;
    }

    @include breakpoint(desktop-small) {
      flex-direction: row;

      .sider-container {
        height: max-content;
      }
    }
  }
}
