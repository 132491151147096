.SignUp {
  background-color: #95DE64;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .navigation {
    padding: 24px;

    .title {
      color: #FFF;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      margin-left: 14px;
    }
  }

  .steps-indicator {
    box-sizing: border-box;
    overflow: hidden;
    padding: 0 10px 24px;
    width: 100vw;
  }

  // .content {
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: left;
  //   align-items: left;
  //   background-color: #FFF;
  //   border-top-left-radius: 24px;
  //   border-top-right-radius: 24px;
  //   padding: 24px;

  //   // .title {
  //   //   color: var(--Neutral-8, #595959);
  //   //   font-size: 16px;
  //   //   font-style: normal;
  //   //   font-weight: 500;
  //   // }

  //   .form {
  //     font-size: 14px;
  //   }

  //   .flex-column {
  //     display: flex;
  //     flex-direction: column;
  //   }
  // }


}