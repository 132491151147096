@import "../../../assets/scss/variables.scss";
@import "../../../assets/scss/mixins.scss";

// tr {
//   min-width: 40px !important;
// }


.SplitPlanDetails {
  .splitTable {
    .expanded-row {
      background-color: #d3d3d3; /* cor de fundo desejada */
    }
    th {
      padding: 10px !important;
    }
    td {
      white-space: nowrap;
      min-width: 140px !important;
      padding: 5px !important;

      input {
        padding: 0 !important;
        border-radius: 0;
        border: unset;
      }
    
      &:nth-child(1) {
        min-width: 1px !important;
      }
    
      &:nth-child(2) {
        min-width: 5px !important;
      }
    }
  }
  .title-page {
    margin: 0;
    color: black;
    font-family: $roboto_light;
    font-size: 30px;
  }

  .container-two {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 20px;
    
    div {
      width: 50%;
    }
  }

  .container-subtitle {
    display: flex;
    justify-content: space-between;

    .badge {
      display: inline-block;
      padding: 5px 7px;
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      color: white;
      font-family: $roboto_bold;
      border-radius: 0.25rem;
      height: fit-content;

      &.badge-success {
        background-color: #21ae41;
      }

      &.badge-error {
        background-color: #da0419;
      }

      &.badge-black {
        background-color: black;
      }
    }
  }

  .subtitle-page {
    margin: 0;
    color: black;
    font-family: $roboto_bold;
    font-size: 20px;
  }

  .container-inputs {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    display: flex;
    flex-direction: column;
    width: 100%;

    div {
      input,
      .ant-select,
      .ant-picker,
      .ant-input-number {
        width: 100%;
        font-size: 15px;
        font-family: $roboto_bold;
        color: #71748d;
      }
    }
  }

  .container-table {
    .badge {
      display: inline-block;
      padding: 5px 7px;
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
      text-transform: capitalize;
      font-family: $roboto_bold;
      background-color: #f3b600;
      color: #2e2f39;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      border-radius: 0.25rem;
    }
  }

  .container-buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 10px;

    button {
      font-family: $roboto_medium;
    }
  }

  .link {
    margin-top: 20px;
    display: flex;

    button {
      display: -ms-flexbox;
      display: flex;
      padding: 10px;
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.5;
      color: #75748d;
      text-align: center;
      white-space: nowrap;
      background-color: #efeff6;
      cursor: pointer;
      border: 1px solid #d2d2e4;
      border-right: 0;
      border-radius: 4px;
      border-radius: 6px 0 0 6px;
    }

    input {
      background-color: #e9ecef;
      border-radius: 0 6px 6px 0;
    }
  }
}
