@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';

.PaymentInfo {
  padding-bottom: 100px;

  @include breakpoint(tablet) {
    padding-bottom: 0;
  }

  .goBack {
    cursor: pointer !important;

    &:hover {
      color: #1677ff;
    }
  }

  .title-page {
    margin: 0;
    color: black;
    font-family: $roboto_light;
    font-size: 30px;
  }

  .container-subtitle {
    display: flex;
    justify-content: space-between;

    .badge {
      display: inline-block;
      padding: 5px 7px;
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      color: white;
      font-family: $roboto_bold;
      border-radius: 0.25rem;
      height: fit-content;

      &.badge-success {
        background-color: #21ae41;
      }

      &.badge-error {
        background-color: #da0419;
      }

      &.badge-black {
        background-color: black;
      }
    }
  }

  .subtitle-page {
    margin: 0;
    color: black;
    font-family: $roboto_bold;
    font-size: 20px;
  }

  .container-inputs {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    display: flex;
    flex-direction: column;

    .ant-form-item {
      .ant-form-item-row {
        flex-direction: column !important;
        height: max-content;
        display: flex;

        .ant-col {
          width: 100% !important;
          display: flex;
          flex: 0 0 0 !important;
          flex-grow: unset !important;
        }
      }
    }

    .helpIcon {
      cursor: pointer;
      margin-left: 8px;
    }

    @include breakpoint(tablet) {
      flex-direction: row;
    }

    .container-left,
    .container-right {
      width: 100%;

      @include breakpoint(tablet) {
        width: 50%;
      }
    }

    .container-right {
      // gap: 30px;
      display: flex;
      flex-direction: column;
    }

    div {
      #expirateDate {
        background: #ffffff;
        border-color: #d9d9d9;
        border-radius: 6px;
        border-style: solid;
        border-width: 1px;
        padding: 5px 11px;

        &:disabled {
          color: #71748d;
          background-color: rgba(0, 0, 0, 0.04);
          border-color: #d9d9d9;
          box-shadow: none;
          cursor: not-allowed;
          opacity: 1;
        }
      }

      input,
      .ant-select,
      .ant-picker,
      .ant-input-number {
        width: 100%;
        font-size: 15px;
        font-family: $roboto_bold;
        color: #71748d;
      }
    }
  }

  .container-table {
    .badge {
      display: inline-block;
      padding: 5px 7px;
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
      text-transform: capitalize;
      font-family: $roboto_bold;
      background-color: #f3b600;
      color: #2e2f39;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      border-radius: 0.25rem;
    }
  }

  .download-section {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
  }

  .container-buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 10px;

    &.footer {
      margin-top: 30px;
      display: flex;
      justify-content: flex-end;
      gap: 10px;
    }

    button {
      font-family: $roboto_medium;
    }
  }

  .link {
    margin-top: 20px;
    display: flex;

    button {
      display: -ms-flexbox;
      display: flex;
      padding: 10px;
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.5;
      color: #75748d;
      text-align: center;
      white-space: nowrap;
      background-color: #efeff6;
      cursor: pointer;
      border: 1px solid #d2d2e4;
      border-right: 0;
      border-radius: 4px;
      border-radius: 6px 0 0 6px;
    }

    input {
      background-color: #e9ecef;
      border-radius: 0 6px 6px 0;
    }
  }

  .badge {
    display: inline-block;
    padding: 3px 7px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    color: white;
    width: min-content !important;
    font-family: $roboto_bold;
    border-radius: 0.25rem;

    &.badge-success {
      background-color: #21ae41;
    }

    &.yellow {
      background-color: rgb(211, 211, 64);
    }

    &.orange {
      background-color: rgb(241, 167, 20);
    }

    &.blue {
      background-color: rgb(20, 145, 241);
    }

    &.black {
      background-color: black;
    }

    &.badge-error {
      background-color: #da0419;
    }
  }

  .boleto-interest-settings {
    h3 {
      margin-top: 24px;
      margin-bottom: 0;
    }
  }
}
