@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.RadioTicket {
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-size: 33px;
  justify-content: flex-start;
  padding: 12px 0;
  width: 100%;

  @include breakpoint(lg) {
    font-size: 2.25rem;
    padding: 20px 0;
  }

  .error-message {
    color: red;
    font-family: $roboto_regular;
    font-size: 16px;
    margin: 0;
  }

  &.selected {
    // margin-left: -15px;
  }

  &.selected,
  &:hover {
    background-color: transparent;
  }

  .container-selects {
    display: flex;
    gap: 10px;
  }
  div {
    align-items: center;
    display: flex;

    p {
      display: block;
      font-size: 17px;
      letter-spacing: 1px;
      font-family: $roboto_regular;
      margin: 0;
    }

    .radio {
      align-items: flex-end;
      display: flex;
      justify-content: flex-start;
      margin-right: 10px;
      width: 15px;
      height: 15px;

      @include breakpoint(lg) {
        width: unset;
      }

      .control {
        background-color: rgb(255, 255, 255);
        border: 1px solid rgb(221, 224, 228);
        border-radius: 4px;
        display: flex;
        height: 15px;
        width: 15px;
      }

      input {
        height: 0;
        margin: 0;
        opacity: 0;
        width: 0;
      }

      input + .control::before {
        background-color: rgb(0, 151, 255);
        border-radius: 30%;
        box-shadow: inset 0.5em 0.5em currentColor;
        color: rgb(0, 151, 255);
        content: '';
        height: 15px;
        transform: scale(0);
        transition: 100ms transform ease-in-out;
        width: 15px;
      }

      input:checked + .control::before {
        transform: scale(1);
      }
    }
  }

  p {
    font-size: 15px;
  }

  .label {
    color: black;
    font-size: 16px;
    font-family: $roboto_regular;
    margin-bottom: 10px;
    text-align: left;

    &::before {
      display: inline-block;
      margin-inline-end: 4px;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
    }
  }
}
