@import "../../../../assets/scss/variables.scss";
@import "../../../../assets/scss/mixins.scss";

.BankRegister {
  .content {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    background-color: #fff;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    padding: 24px;

    .title {
      align-items: center;
      color: #595959;
      cursor: pointer;
      display: flex;
      font-family: $roboto_regular;
      font-size: 16px;

      .ion--arrow-back {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='none' stroke='black' stroke-linecap='round' stroke-linejoin='round' stroke-width='48' d='M244 400L100 256l144-144M120 256h292'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: inline-block;
        height: 20px;
        margin-right: 5px;
        width: 20px;
      }
    }

    .form {
      display: flex;
      flex-direction: column;

      .button-continue {
        margin-top: 100px;
      }

      .container-form {
        display: flex;
        flex-direction: column;
        gap: 40px;

        .two-bank {
          display: flex;
          gap: 18px;

          .first-div {
            width: 30%;
          }

          div {
            width: 100%;
          }
        }

        .date, .ant-select-selector, .ant-select {
          width: 100% !important;
          border-radius: 3px;
        }

        p {
          font-size: 14px;
          font-family: $roboto_regular;
          color: black;
          margin: 0 0 16px 0;
        }

        input {
          border-radius: 3px;
        }
      }
    }

    .flex-column {
      display: flex;
      flex-direction: column;
      gap: 8px;

      button {
        border-radius: 3px;
      }
    }
  }
}
