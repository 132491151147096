@import "../../../../../assets/scss/variables.scss";
@import "../../../../../assets/scss/mixins.scss";

.DivisionFees {
  margin-bottom: 100px;

  @include breakpoint(tablet) {
    margin-bottom: 0;
  }

  .error-text {
    color: red;
  }

  .error-select {
    .ant-select-selector:not(.ant-select-disabled) {
      border-color: red !important;
    }
  }

  .subtitle-page {
    margin: 20px 0;
    color: black;
    font-family: $roboto_regular;
    font-size: 20px;
  }

  .container-inputs {
    display: flex;
    gap: 20px;
    flex-direction: column;

    .left-inputs,
    .right-inputs {
      width: 100%;
    }

    .input-container {
      display: flex;
      align-items: center;
      gap: 10px;

      .eye-icon {
        background-color: rgba(0, 0, 0, 0.04);
        border-color: #d9d9d9;
        cursor: pointer;
        padding: 5px 10px;
        box-sizing: border-box;
        border-width: 1px;
        border-style: solid;
        border-radius: 6px;
        height: 34px;

        &:hover {
          background-color: #f4f4f4;
          border-color: #d9d9d9;
          box-shadow: none;
          color: #1677ff;
          transition: all 0.3s ease-in-out;
        }

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }

    @include breakpoint(desktop-small) {
      flex-direction: row;

      .left-inputs,
      .right-inputs {
        width: 50%;
      }
    }
  }
}
