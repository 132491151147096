@import "../../../assets/scss/variables.scss";
@import "../../../assets/scss/mixins.scss";

.field {
  padding: 15px 30px 0 0;
  box-sizing: border-box;
  width: 100%;

  @include breakpoint(tablet) {
    width: 50%;
  }

  &.small {
    width: auto;
  }

  .title {
    font-family: $josefinsans_regular;
    font-size: 16px;
    margin: 0 0 5px 0;
    color: black;
  }

  .input {
    border-radius: 5px;
    border-style: solid;
    border-width: 2px;
    height: 48px;
    padding-left: 15px;
    font-weight: 600;
    font-size: 14px;
    color: #5e6977;
  }

  .ddl {
    border-color: #f0f4f7;
    background-color: #f0f4f7;
    width: 100px;
    margin-right: 10px;
  }
}
