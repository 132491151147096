@import "../../../assets/scss/variables.scss";
@import "../../../assets/scss/mixins.scss";

.progress-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 20px; /* Espaçamento opcional abaixo do progresso */
}

.step {
  position: relative;
  flex: 1;
  text-align: center;
}

.bullet {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgba(240, 240, 240, 1);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.step-title {
  position: relative;
  top: 20px;
  font-size: 13px;
  font-family: $roboto_regular;
}

.step.active .bullet {
  background-color: #007bff !important;
}

.step.passed .bullet {
  background-color: #237804;
}

.step:not(.active):not(:last-child).passed + .bullet {
  background-color: #237804;
}

.step:not(.active):not(.passed) .bullet {
  background-color: rgba(
    240,
    240,
    240,
    1
  );
}

/* Estilo do elemento <progress> */
progress {
  width: 75%;
  height: 4px;
  appearance: none; 
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1;
}

/* Estilização da barra de progresso */
progress::-webkit-progress-bar {
  background-color: rgba(
    240,
    240,
    240,
    1
  ); 
}

/* Estilização da parte preenchida da barra de progresso */
progress::-webkit-progress-value {
  background-color: #237804; 
}

/* Estilização da borda da barra de progresso */
progress::-webkit-progress-bar,
progress::-webkit-progress-value {
  border-radius: 5px;
}
