@import "../../../../assets/scss/variables.scss";
@import "../../../../assets/scss/mixins.scss";

.DataRegister {
  height: 100% !important;

  .content {
    align-items: left;
    background-color: #fff;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: left;
    min-height: 80vh;
    padding: 24px 24px 50px;

    .title {
      align-items: center;
      color: #595959;
      cursor: pointer;
      display: flex;
      font-family: $roboto_bold;
      font-size: 16px;

      .ion--arrow-back {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='none' stroke='black' stroke-linecap='round' stroke-linejoin='round' stroke-width='48' d='M244 400L100 256l144-144M120 256h292'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: inline-block;
        height: 20px;
        margin-right: 5px;
        width: 20px;
      }
    }

    .form {
      // position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      min-height: 50vh;

      p {
        font-size: 14px;
        font-family: $roboto_regular;
        color: black;
        margin: 0 0 16px 0;
      }

      input {
        border-radius: 2px;
      }

      .container-inputs {
        display: flex;
        flex-direction: column;
        gap: 40px;
      }

      .cpf {
        background: #ffffff;
        border-color: #d9d9d9;
        border-radius: 7px;
        border-style: solid;
        border-width: 1px;
        box-sizing: border-box;
        color: rgba(0, 0, 0, 0.88);
        display: inline-block;
        font-size: 14px;
        height: 40px;
        border-radius: 2px;
        line-height: 1.5714285714285714;
        list-style: none;
        margin: 0;
        min-width: 0;
        padding: 4px 11px;
        position: relative;
        transition: all 0.2s;
        width: 100%;

        &:focus {
          border-color: #4096ff !important;
          outline: none;
        }

        &::placeholder {
          color: #d9d9d9;
        }
      }

      .date, .ant-select-selector, .ant-select {
        width: 100% !important;
        border-radius: 2px;
      }

      .button-continue {
        margin-top: 100px;
      }
    }

    .flex-column {
      display: flex;
      flex-direction: column;
      gap: 8px;

      button {
        border-radius: 3px;
      }
    }
  }
}
