.Login {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;

  .container-buttons {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .form-container {
    width: 100%;
    padding: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: 768px) {
      width: 460px;
      padding: 80px;
    }

    .logo {
      display: flex;
      justify-content: center;
      padding-bottom: 40px;

      img {
        height: 85px;
      }
    }

    .form {
      display: flex;
      gap: 16px;
      flex-direction: column;
    }

    .register {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 8px;

      button {
        width: 100%;
      }
    }
  }

  .background {
    width: 100%;
    background-color: #94FFAB;
    justify-content: end;
    align-items: flex-end;
    padding: 40px;
    display: none;

    @media (min-width: 768px) {
      display: flex;
    }

    span {
      color: #004C11;
      font-size: 20px;
      width: 270px;
      text-align: right;
    }
  }
}