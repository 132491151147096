@import "../../../../assets/scss/variables.scss";
@import "../../../../assets/scss/mixins.scss";

.Accounts {
  .title-page {
    margin: 0;
    color: black;
    font-family: $roboto_light;
    font-size: 30px;
  }

  .subtitle-page {
    margin: 0;
    color: black;
    font-family: $roboto_bold;
    font-size: 20px;
  }

  .box-divider {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
  }

  .badge {
    display: inline-block;
    padding: 3px 7px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    vertical-align: baseline;
    color: white;
    width: min-content !important;
    font-family: $roboto_bold;
    border-radius: 0.25rem;

    &.badge-success {
      background-color: #fff700;
      color: black;
    }

    &.badge-error {
      background-color: #000000;
    }
  }

  .expandid {
    span {
      align-items: center;
      display: flex;
      gap: 10px;
      font-family: $roboto_bold;
      width: 100%;

      p {
        margin: 5px 0;
        font-family: $roboto_light;
      }
    }
  }
}
